<template>
  <div ref="customDialog">
    <v-dialog v-model="awaitingResponse" fullscreen hide-overlay>
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog
      v-model="isVisible"
      scrollable
      :retain-focus="false"
      :max-width="small ? 500 : 1000"
      content-class="overflow-visible"
      :attach="$refs.customDialog"
      :close="cancel"
      @keydown.esc="cancel"
      @click:outside="cancel"
    >
      <v-card class="pt-2 pb-3" max-height="75vh">
        <div style="position: relative">
          <v-btn
            :disabled="closeDisabled"
            fab
            absolute
            top
            color="secondary"
            small
            right
            elevation="2"
            class="exit-btn mr-n8"
            @click="cancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="font-weight-bold mt-n2">
          {{ showFormTitle ? showTitle : title }}
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-alert v-if="error" type="error" dismissible>
            {{ error }}
          </v-alert>
          <slot></slot>
        </v-card-text>
        <v-card-actions v-show="showSubmit" class="justify-end">
          <v-btn v-if="draftEnabled" @click="$emit('submit:draft')">
            Save draft
          </v-btn>
          <v-btn
            v-else
            :disabled="closeDisabled"
            class="mr-2"
            color="secondary"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="submitDisabled"
            color="primary"
            @click="
              $emit('submit')
              $store.state.itemToEdit = null
            "
          >
            {{ submitLabel ? submitLabel : editable ? "Update" : "Submit" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "form-dialog",
  props: {
    closeDisabled: {
      type: Boolean,
      required: false,
    },
    submitDisabled: {
      type: Boolean,
      required: false,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    awaitingResponse: {
      type: Boolean,
      required: false,
    },
    showSubmit: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitLabel: {
      type: String,
      required: false,
      default: "",
    },
    showFormTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    draftEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.visible,
    }
  },
  computed: {
    showTitle() {
      return this.editable ? "Update " + this.title : "Add New " + this.title
    },
    editable() {
      return this.editMode && this.itemToEdit != null
    },
    itemToEdit() {
      return this.$route.params.id || this.$store.state.itemToEdit
    },
  },
  watch: {
    visible(newVal) {
      this.isVisible = newVal
    },
  },
  methods: {
    cancel() {
      if (!this.closeDisabled && !this.awaitingResponse) {
        this.$emit("close")
        this.$store.state.itemToEdit = null
        this.$router.push({ params: { form: null, id: null } })
      }
    },
  },
}
</script>

<style scoped>
.exit-btn {
  border: solid 1px #666 !important;
}
</style>
